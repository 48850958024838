'use strict';

const { CLASSES } = require('eminence/utils/globals');
const SELECTORS = {
    body: 'body',
    inputField: '.js-input-field',
    inputFieldWrapper: '.js-field-wrapper, .form-group',
    inputFieldInvalid: '.f-field.is-invalid',
    inputFieldIsInvalid: '.is-invalid',
    invalidFieldMessage: '.js-invalid-message, .invalid-feedback',
    formControlIsInvalid: 'form-control.is-invalid',
};

/**
 * Validate form element.
 * @param {jQuery} element - Element witch need to valid
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateElement(element) {
    element.setCustomValidity('');
    if (!element.checkValidity()) {
        $(element).trigger('invalid', element.validity);
    } else {
        $(element).removeClass(CLASSES.isInvalid);
        $(element).closest(SELECTORS.inputFieldWrapper).find(SELECTORS.invalidFieldMessage).empty();
        $(element).closest(SELECTORS.inputFieldWrapper).find(SELECTORS.inputFieldInvalid).removeClass(CLASSES.isInvalid);
        $(element).closest(SELECTORS.inputFieldWrapper).find(SELECTORS.inputFieldIsInvalid).removeClass(CLASSES.isInvalid);
        $(element).closest(SELECTORS.inputFieldWrapper).removeClass(CLASSES.isInvalid);
    }
}

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        $(this).find('input, select, textarea').each(function () {
            validateElement(this);
        });
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find(SELECTORS.inputFieldInvalid).removeClass(CLASSES.isInvalid);
    $(form).find(SELECTORS.formControlIsInvalid).removeClass(CLASSES.isInvalid);

    $('body').on('form:clear', function (e, data) {
        data.form.find(SELECTORS.inputFieldInvalid).removeClass(CLASSES.isInvalid);
    });
}

module.exports = {
    invalid: function () {
        $(SELECTORS.body).on('invalid', 'form input, form select, form textarea', function (e) {
            e.preventDefault();
            this.setCustomValidity('');

            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $(this).parent().addClass(CLASSES.isInvalid);
                $(this).addClass(CLASSES.isInvalid);

                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                if (this.validity.typeMismatch && $(this).data('type-mismatch')) {
                    validationMessage = $(this).data('type-mismatch');
                }

                $(this).parents(SELECTORS.inputFieldWrapper).find(SELECTORS.invalidFieldMessage)
                    .text(validationMessage);
            }
        });
    },

    submit: function () {
        $('form').on('submit', function (e) {
            return validateForm.call(this, e);
        });
    },

    inputChange: function () {
        $('body').on('focusout', 'form input', function () {
            validateElement(this);
        });
    },

    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        clearForm: clearForm
    }
}
