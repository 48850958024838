'use strict';

module.exports = function () {
    var sizes = ['xs', 'sm', 'md', 'lg', 'xl'];

    sizes.forEach(function (size) {
        var selector = `.collapsible-${size} .title, .collapsible .title`;
        var collapsible = `.collapsible-${size}, .collapsible`;
        $('body').on('click', selector, function (e) {
            e.preventDefault();
            $(this).parents(collapsible).toggleClass('active');

            if ($(this).parents(collapsible).hasClass('active')) {
                $(this).attr('aria-expanded', true);
            } else {
                $(this).attr('aria-expanded', false);
            }
        });
    });
};
