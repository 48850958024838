// Global CSS classes
export const CLASSES = {
    show: 'show',
    open: 'open',
    disabled: 'disabled',
    sticky: 'sticky',
    active: 'active',
    hide: 'd-none h-hide',
    hidden: 'h-hidden',
    isValid: 'is-valid',
    isInvalid: 'is-invalid',
    isError: 'is-error',
    overflowHidden: 'h-overflow-hidden',
    selectOpen: 'select-open',
    display: 'd-block',
    selected: 'selected',
    isManuall: 'manuall',
};

export const CONFIG = {
    breakpointMedium: 600,
    breakpointDesktop: 900
};
