'use strict';

const { CLASSES } = require('../utils/globals');

const SELECTORS = {
    body: 'body',
    closePopup: '.js-basket-popup-close',
    background: '.js-basket-popup-background',
    slide: '#basket-popup',
    popupMessage: '.js-basket-popup-message',
    continueShopping: '.js-button-popup-close',
    errorPopup: '#basket-popup-error',
    errorBackground: '.js-basket-popup-background-error'
};

module.exports = function (response, form) {
    let cart = response.cart;
    let product = {};

    for (var i = 0, items = cart.items; i < items.length; i++) {
        if (items[i].id == form.pid) {
            product = items[i];
            break;
        }
    }
    var noOfItemsInCart = cart.numItems;
    const {
        productName: name,
        shortDescription: subtitle,
        variationAttributes,
        images,
        price,
    } = product || {};

    const size = (variationAttributes && variationAttributes[0] && variationAttributes[0].displayValue) || '';
    const image = (images && images.small && images.small[0]) || {};
    const listPrice = (price && price.list && price.list.formatted) || '';
    const salesPrice = (price && price.sales && price.sales.formatted) || '';
    const subTotal = cart.totals.subTotal;
    const discount = (cart && cart.approachingDiscounts && cart.approachingDiscounts[0]) || {}; // TODO: We would have to extend this in future to support multiple promotions
    const discountMsg = discount.discountMsg || 'Congratulations! You received free shipping';
    const discountTotalStr = discount.lineItemTotal || "$ 0";
    const discountThresholdStr = discount.thresholdValue || "$ 1";

    // Convert and extract the number so we can calculate the progress percentage
    const extractNumber = (str) => parseFloat(str.replace(/[^0-9.-]+/g,""));
    const discountTotal = extractNumber(discountTotalStr);
    const discountThreshold = extractNumber(discountThresholdStr);

    // Calculate progress percentage and ensure it doesn't exceed 100%
    let progressPercentage = 0;
    if (!cart.approachingDiscounts || !cart.approachingDiscounts.length) {
        progressPercentage = 100;
    } else {
        progressPercentage = Math.min((discountTotal / discountThreshold) * 100, 100).toFixed(2);
    }

    // Progress bar width update
    $('.b-basket-popup-footer-shipping-bar::after').css('width', `${progressPercentage}%`);

    let popUpHtml = `<div id="basket-popup" class="b-basket-popup">
                        <div class="b-basket-popup-header">
                            <div class="b-basket-popup-header-title">
                                <span class="b-basket-popup-checkmark"></span>
                                <h4 class="b-basket-popup-message js-basket-popup-message heading-h4"></h4>
                            </div>
                            <button class="c-modal-close js-basket-popup-close" tabindex="0">close</button>
                        </div>
                        <div class="b-basket-popup-product">
                            <div class="b-basket-popup-product-image">
                                ${image ? `<img src="${image.url}" alt="${image.alt}">` : ''}
                            </div>
                            <div class="b-basket-popup-product-content">
                                ${name ? `<div class="b-basket-popup-product-name">${name}</div>` : ''}
                                ${subtitle ? `<div class="b-basket-popup-product-subtitle">${subtitle}</div>` : ''}
                                <div class="b-basket-popup-product-price">
                                    ${listPrice ? `<span class="b-basket-popup-product-price-list">${listPrice}</span>` : ''}
                                    ${salesPrice ? `<span class="b-basket-popup-product-price-sales">${salesPrice}</span>` : ''}
                                </div>
                                <div class="b-basket-popup-product-info">
                                    ${size ? `<span class="b-basket-popup-product-info-size">Retail Size ${size}</span>` : ''}
                                    ${form.quantity ? `<span class="b-basket-popup-product-info-qty">Quantity: ${form.quantity}</span>` : ''}
                                </div>
                            </div>
                        </div>
                        <div class="b-basket-popup-footer">
                            <div class="b-basket-popup-footer-top">
                                ${noOfItemsInCart ? `<span class="b-basket-popup-footer-top-quantity">${noOfItemsInCart} Item${noOfItemsInCart ? 's' : ''} in your basket</span>` : ''}
                                ${subTotal ? `<span class="b-basket-popup-footer-top-subtotal">Subtotal: ${subTotal}</span>` : ''}
                            </div>
                            <div class="b-basket-popup-footer-shipping ${!cart.totals.shippingLevelDiscountTotal.value && (!cart.approachingDiscounts || !cart.approachingDiscounts.length) ? 'd-none' : ''}">
                                <div class="b-basket-popup-footer-shipping-bar">
                                    <div class="progress" style="width: ${progressPercentage}%;"></div>
                                </div>
                                ${discountMsg ? `<div class="b-basket-popup-footer-shipping-message">${discountMsg}</div>` : ''}
                            </div>
                            <div class="b-basket-popup-footer-buttons">
                                <button class="b-button-popup-close js-button-popup-close f-button f-button--secondary">Continue Shopping</button>
                                <a class="f-button f-button--primary" href="${response.cartShowURL}">View or Edit Basket</a>
                            </div>
                        </div>
                    </div>
                    <div class="b-basket-popup-background js-basket-popup-background"></div>`;

    let errorHtml = `<div id="basket-popup-error" class="c-modal b-basket-popup-error">
                        <div class="c-modal-header">
                            <h3 class="heading-h3">Product Unavailable</h3>
                            <button class="b-basket-popup-close js-basket-popup-close" tabindex="0">close</button>
                        </div>
                        <div class="b-basket-popup-error-content">
                            <p class="b-basket-popup-error-content-text">We're sorry, but it seems there's an issue adding the product to your basket at the moment. This could be due to technical difficulties on our end or temporary unavailability of the product. Please try again later.</p>
                        </div>
                        <div class="b-basket-popup-error-footer">
                            <button class="b-button-popup-close js-button-popup-close f-button f-button--secondary">Close</button>
                        </div>
                    </div>
                    <div class="b-basket-popup-background js-basket-popup-background-error"></div>`;

    const appendHtmlToBody = (html) => {
        $(SELECTORS.body).append(html);
    };

    if ($(SELECTORS.slide).length === 0) {
        const htmlToAppend = response.error ? errorHtml : popUpHtml;
        appendHtmlToBody(htmlToAppend);
    }

    $(SELECTORS.popupMessage).text(response.message);
    $(SELECTORS.slide).addClass(CLASSES.show);

    const closePopup = () => {
        // Remove Error Popup and background
        $(SELECTORS.errorPopup).remove();
        $(SELECTORS.errorBackground).remove();

        // Remove Success Message Popup and background
        $(SELECTORS.slide).removeClass(CLASSES.show);
        setTimeout(function () {
            $(SELECTORS.slide).remove();
            $(SELECTORS.background).remove();
        }, 300);
    };

    $(`${SELECTORS.closePopup}, ${SELECTORS.continueShopping}, ${SELECTORS.background}`).on('click', closePopup);
};
