'use strict';

var SELECTORS = {
    passwordRequirementsContainer: '.c-password-requirements',
    passwordRequirement: '.c-password-requirements-item',
    passwordInputField: '.c-input-password'
}

const { CLASSES } = require('eminence/utils/globals');

/**
 * @description Initializes the "password meets requirements" functionality
*/
function passwordRequirements() {
    const requirementsContainer = document.querySelector(SELECTORS.passwordRequirementsContainer);

    if (requirementsContainer !== null) {
        const passwordInput = requirementsContainer.previousElementSibling.querySelector(SELECTORS.passwordInputField);

        if (passwordInput) {
            passwordInput.addEventListener('input', function () {
                const $this = this;
                let inputValue = $this.value;

                const requirements = Array.from(document.querySelectorAll(SELECTORS.passwordRequirement));

                var allRequirementsMet = true;
                requirements.forEach(requirement => {
                    let regex = requirement.getAttribute('data-regex') || '[]';
                    if ((new RegExp(regex)).test(inputValue)) {
                        requirement.classList.add(CLASSES.selected);
                    } else {
                        requirement.classList.remove(CLASSES.selected);
                        allRequirementsMet = false;
                    }
                });

                if (allRequirementsMet) {
                    $this.classList.remove(CLASSES.isInvalid);
                } else {
                    $this.classList.add(CLASSES.isInvalid);
                }
            });
        }
    }
}

module.exports = passwordRequirements;
