'use strict';

var baseLogin = require('base/login/login');

var formValidation = require('base/components/formValidation');
var createNotification = require('eminence/components/notification');

var location = window.location;

const SELECTORS = {
    registrationForm: '.js-registration-form',
    errorMessaging: '.error-messaging',
    selectedSkinCOncerns: '.js-registration-form-skinconcerns-selected',
    resetPasswordForm: '.js-reset-password-form',
    requestPasswordTitle: '.request-password-title',
    requestPasswordBody: '.js-request-password-body',
    submitEmailButton: '.js-submit-email-button',
    sendEmailButton: '.js-send-email-btn',
    passwordResetSubtitle: '.js-password-reset-subtitle'
}

function register () {

    $(SELECTORS.registrationForm).on('submit', function (e) {
        var form = $(this);
        e.preventDefault();

        var url = form.attr('action');
        form.spinner().start();
        var $skinConcerns = $('input.js-skinconcerns-checkbox:checked');
        var skinConcernsIDs = [];
        $skinConcerns.each(function () {
            skinConcernsIDs.push($(this).attr('id'));
        });
        $(SELECTORS.selectedSkinCOncerns).val(JSON.stringify(skinConcernsIDs));

        $(SELECTORS.registrationForm).trigger('login:register', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    $(SELECTORS.registrationForm).trigger('login:register:error', data);
                    formValidation(form, data);
                } else {
                    $(SELECTORS.registrationForm).trigger('login:register:success', data);
                    location.href = data.redirectUrl;
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createNotification($(SELECTORS.errorMessaging), err.responseJSON.message, 'danger');
                }

                form.spinner().stop();
            }
        });
        return false;
    });
}

function resetPassword () {
    $(SELECTORS.resetPasswordForm).on('submit', function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        $(SELECTORS.resetPasswordForm).trigger('login:register', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                } else {
                    $(SELECTORS.requestPasswordTitle).text(data.receivedMsgHeading);
                    $(SELECTORS.passwordResetSubtitle).remove();
                    $(SELECTORS.requestPasswordBody).empty()
                        .append('<p>' + data.receivedMsgBody + '</p>');
                    if (!data.mobile) {
                        $(SELECTORS.submitEmailButton).text(data.buttonText)
                            .attr('data-dismiss', 'modal');
                    } else {
                        $(SELECTORS.sendEmailButton).empty()
                            .html('<a href="'
                                + data.returnUrl
                                + '" class="f-button f-button--primary">'
                                + data.buttonText + '</a>');
                    }
                }
            },
            error: function () {
                form.spinner().stop();
            }
        });
        return false;
    });
}

baseLogin.register = register;
baseLogin.resetPassword = resetPassword;

module.exports = baseLogin;
