'use strict';

require('slick-carousel/slick/slick');

const { CONFIG } = require('../utils/globals');

const SELECTORS = {
    body: 'body',
    window: window,
    slickSlider: '.js-slick-slider',
    slickSliderMobile: '.js-slick-slider-mobile',
    slickSliderDesktop: '.js-slick-slider-desktop',
    slickSliderWrapper: '.js-slick-slider-wrapper',
    slickSliderPrevBtn: '.slick-prev',
    slickSliderNextBtn: '.slick-next',
    videoIframe: '.js-video-iframe'
};

const LOCAL_CLASSES = {
    slickInitialized: 'slick-initialized'
};

let $slickSliders;
let $slickSlidersMobile;

// Progress bar and Pause/Play button functionality
$(document).ready(function() {
    $slickSliders = $(SELECTORS.slickSlider);
    const progress = $('.slick-progress');
    const stateButton = $('.slick-state-button');
    const slideDuration = 5000;
    let isPaused = false;
    let $slickSlider = null;

    // Progress bar animation
    const startProgressBar = () => {
        if (!isPaused) {
            progress.stop().css({ width: '0%' }).animate({
                width: '100%'
            }, slideDuration, 'linear', function() {
                $slickSlider.slick('slickNext');
            });
        }
    };

    // Pause/play button
    stateButton.on('click', function() {
        const $button = $(this);
        if (isPaused) {
            $button.addClass('slick-pause-button');
            $button.removeClass('slick-play-button');
            isPaused = false;
            $slickSlider.slick('slickPlay');
            startProgressBar();
        } else {
            $button.removeClass('slick-pause-button');
            $button.addClass('slick-play-button');
            isPaused = true;
            $slickSlider.slick('slickPause');
            progress.stop();
        }
    });

    // Function to restart progress bar and timer
    const restartProgressBar = () => {
        progress.stop().css({ width: '0%' });
        startProgressBar();
    };

    // Initialize slick slider
    if ($slickSliders.length) {
        $slickSlider = $slickSliders.eq(0);

        // Start progress bar animation
        if(stateButton.length) {
            startProgressBar();
        }

        if (!$slickSlider.hasClass(LOCAL_CLASSES.slickInitialized)) {
            $slickSlider.slick();
        }

        // Pause slick slider when progress bar is clicked
        progress.on('click', function() {
            $slickSlider.slick('slickPause');
            progress.stop();
        });

        // Add event listener for previous and next arrow buttons
        $slickSlider.on('click', SELECTORS.slickSliderPrevBtn, function() {
            restartProgressBar();
        });

        $slickSlider.on('click', SELECTORS.slickSliderNextBtn, function() {
            restartProgressBar();
        });

        // Reset progress bar on slide change
        $slickSlider.on('afterChange', function() {
            startProgressBar();
        });
    }
});

const handleInitSliders = () => {
    $(SELECTORS.slickSliderPrevBtn).hide();

     // Progress bar functionality
     if ($slickSliders) {
        $slickSliders.on('beforeChange', function(event, slick) {
            const progress = $('.js-slick-progress');
            const slideDuration = slick.options.autoplaySpeed || 5000;

            progress.stop().css({ width: '0%' }).animate({
                width: '100%'
            }, slideDuration, 'linear');
        });
    }

    $slickSliders = $(SELECTORS.slickSlider);


    let windowWidth = $(window).innerWidth();

    // init only on Mobile
    if (windowWidth < CONFIG.breakpointMedium) {
        $slickSlidersMobile = $(SELECTORS.slickSliderMobile);
        $slickSlidersMobile.each(function () {
            let $slickSliderMobile = $(this);
            let slickMobileOptions = $slickSliderMobile.attr('data-slick') ? JSON.parse($slickSliderMobile.attr('data-slick')) : null;
            if (!$slickSliderMobile.hasClass(LOCAL_CLASSES.slickInitialized)) {
                $slickSliderMobile.slick(slickMobileOptions ? slickMobileOptions : {});
            }
        });
    }

    // init only on Desktop
    if (windowWidth < CONFIG.breakpointDesktop) {
        $slickSlidersMobile = $(SELECTORS.slickSliderDesktop);
        $slickSlidersMobile.each(function () {
            let $slickSliderDesktop = $(this);
            let slickMobileOptions = $slickSliderDesktop.attr('data-slick') ? JSON.parse($slickSliderDesktop.attr('data-slick')) : null;
            if (!$slickSliderDesktop.hasClass(LOCAL_CLASSES.slickInitialized)) {
                $slickSliderDesktop.slick(slickMobileOptions ? slickMobileOptions : {});
            }
        });
    }
};

const handleResizeSliders = () => {
    $slickSliders.each(function () {
        let $slickSlider = $(this);
        if ($slickSlider && $slickSlider.length && !$slickSlider.hasClass(LOCAL_CLASSES.slickInitialized)) {
            $slickSlider.slick();
        }
    });
};

const handleSliderReInit = (event, data) => {
    const $slider = data.slider ? data.slider : $(SELECTORS.slickSlider);
    if (!$slider.hasClass(LOCAL_CLASSES.slickInitialized)) {
        $slider.slick();
    }
};

/**
 * Resizes the video iframe if any in the slick slide
 * @param {jQuery} $iframes - video iframe
 * @param {Number} ratio - aspect ratio to be used, ex 16/9
 */
function resizePlayer($iframes, ratio) {
    if (!$iframes[0]) {
        return
    }

    var win = $(SELECTORS.slickSlider),
        width = win.width(),
        playerWidth,
        height = win.height(),
        playerHeight;

    $iframes.each(function() {
        var current = $(this);
        if (width / ratio < height) {
            playerWidth = Math.ceil(height * ratio);
            current.width(playerWidth).height(height).css({
                left: (width - playerWidth) / 2,
                top: 0
            });
        } else {
            playerHeight = Math.ceil(width / ratio);
            current.width(width).height(playerHeight).css({
                left: 0,
                top: (height - playerHeight) / 2
            });
        }
    });
}

module.exports = function () {
    handleInitSliders();

    $(SELECTORS.window).on('load', handleInitSliders);
    $(SELECTORS.window).on('load', handleResizeSliders);
    $(SELECTORS.window).on('resize', handleResizeSliders);
    $(SELECTORS.window).on('popstate', handleInitSliders);
    $(SELECTORS.window).on('resize.slickVideoPlayer', resizePlayer($(SELECTORS.slickSlider).find(SELECTORS.videoIframe), 16/9));

    $(SELECTORS.body).on('slick:reInit', handleSliderReInit);
    $(SELECTORS.body).on('click', SELECTORS.slickSliderPrevBtn, function() {
        const $slickSlider = $(this).parents(SELECTORS.slickSliderWrapper).find(SELECTORS.slickSlider);
        $slickSlider.slick('slickPrev');
    });

    $(SELECTORS.body).on('click', SELECTORS.slickSliderNextBtn, function() {
        const $slickSlider = $(this).parents(SELECTORS.slickSliderWrapper).find(SELECTORS.slickSlider);
        $slickSlider.slick('slickNext');
    });

    $(SELECTORS.body).on('slick:reInit', handleSliderReInit);
};
