'use strict';

module.exports = function (element, message, notificationType, mls) {
    var notificationHtml = `<div
                                class="l-container alert alert-${notificationType} alert-dismissible fade show"
                                role="alert"
                            >
                                <span class="alert-${notificationType}-icon"></span>
                                <p class="alert-content">
                                    ${message}
                                </p>
                                <button
                                    type="button"
                                    class="close
                                    close-icon"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                </button>
                            </div>`;

    $(element).empty().append(notificationHtml);

    setTimeout(function () {
        $(element).empty();
    }, mls ? mls : 2000);
};
