'use strict';

function appendParamToURL(url, name, value) {
    // quit if the param already exists
    if (url.indexOf(name + '=') !== -1) {
        return url;
    }
    var separator = url.indexOf('?') !== -1 ? '&' : '?';
    if (typeof value === 'object') {
        return url + separator + name + '=' + encodeURIComponent(JSON.stringify(value));
    } else {
        return url + separator + name + '=' + encodeURIComponent(value);
    }
}

function removeParamFromURL(url, paramName) {
    let urlAndParams = url.split('?');
    let params = urlAndParams.length > 1 ? urlAndParams[1].split('&') : [];
    for (var i = 0; i < params.length; i++) {
        let param = params[i];
        if (param.split('=')[0] == paramName) {
            params.splice(i, 1);
            break;
        }
    }
    url = urlAndParams[0];
    if (params.length) {
        url += '?';
        url += params.join('&');
    }
    return url;
}

module.exports = {
    appendParamToURL,
    removeParamFromURL
};
