'use strict';

const { CLASSES } = require('eminence/utils/globals');
const SELECTORS = {
    showPasswordBtn: '.js-show-password',
    inputField: '.js-input-field',
}

/**
 * @description Initializes the password show/hide functionality
 */
function passwordShow() {
    const showPasswordBtns = Array.from(document.querySelectorAll(SELECTORS.showPasswordBtn));
    showPasswordBtns.forEach(showPasswordBtn => {
        showPasswordBtn.addEventListener('click', function () {
            const $this = $(this);

            showPasswordBtn.classList.toggle(CLASSES.active);

            const $input = $this.siblings(SELECTORS.inputField);
            if ($input.length) {
                const isPasswordType = $input.attr('type') === 'password';
                const showPasswordTitle = $this.data('title-show');
                const hidePasswordTitle = $this.data('title-hide');

                $input.attr('type', isPasswordType ? 'text' : 'password');
                $this.attr('title', isPasswordType ? hidePasswordTitle : showPasswordTitle);
                $this.text(isPasswordType ? hidePasswordTitle : showPasswordTitle);

                $input.focus();
            }
        });
    });
}

module.exports = passwordShow();
