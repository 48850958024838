'use strict';

const SELECTORS = {
    cookieContainer: '.js-cookie-container', // Container that contains the URLs
    cookieConsentBtn: '.js-cookie-consent', // Cookie accept/reject button
}

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

module.exports = {
    checkCookie: function () {
        if ($('.valid-cookie-warning').length > 0) {
            var customerRespondedOnCookieConsent = getCookie('customerRespondedOnCookie');
            if (!customerRespondedOnCookieConsent) {
                // If the customer hasn't previously responded on cookie consent, show the cookie alert
                $('.cookie-warning-messaging').show();
            } else {
                // Hide the cookie alert if user has already responded to cookie consent
                $('.cookie-warning-messaging').hide();
            }
        }
    },
    acceptRejectCookieConsent: function () {
        $(document).on('click', SELECTORS.cookieConsentBtn, function (e) {
            e.preventDefault();
            var consent = $(this).data('consent');
            var url = $(SELECTORS.cookieContainer).data(consent);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json'
            });
            return false;
        });
    }
};
