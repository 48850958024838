'use strict';

var createNotification = require('eminence/components/notification');

const SELECTORS = {
    newsletterForm: '.js-newsletter-form',
    newsletterInput: '.js-newsletter-input',
    newsletterInputWrapper: '.js-newsletter-input-wrapper',
    notificationMessage: '.js-notification-message, .error-messaging',
};

/**
 * Init all module related events here
 * @returns {void}
 */
const initEvents = () => {
    $(SELECTORS.newsletterForm).on('submit', handleNewsletterSignUp);
}

/**
 * Get user email value
 * @returns {String} email
 */
const getEmailValue = function () {
    let email = $(SELECTORS.newsletterInput).val();
    return email && email.length ? email.trim() : null;
};

/**
 * Checks if the email value entered is correct format
 * @param {string} email - email string to check if valid
 * @returns {boolean} Whether email is valid
 */
function validateEmail(email) {
    var regex = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
    return regex.test(email);
}

/**
 * Handle Newsletter Sign Up
 * @returns {void}
 */
const handleNewsletterSignUp = function (e) {
    e.preventDefault();

    // const $form = $(this);
    // const url = $form.attr('action');

    $(SELECTORS.newsletterInputWrapper).spinner().start();

    if (validateEmail(getEmailValue())) {
        createNotification($(SELECTORS.notificationMessage), 'Email is valid!', 'success');
    } else {
        createNotification($(SELECTORS.notificationMessage), 'Please, provide correct E-mail', 'danger');
    }

    setTimeout(() => {
        $(SELECTORS.newsletterInputWrapper).spinner().stop();
    }, 1000);


    // $.ajax({
    //     url: url,
    //     type: 'POST',
    //     dataType: 'json',
    //     data: $form.serialize(),
    //     success: function (data) {
    //         $(SELECTORS.newsletterInputWrapper).spinner().stop();
    //     },
    //     error: function (err) {
    //         $(SELECTORS.newsletterInputWrapper).spinner().stop();
    //     }
    // });
    // return false;
};

module.exports = {
    initEvents: initEvents,
}
