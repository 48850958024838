'use strict';

const SELECTORS = {
    charactersCounter: '.js-field-characters-counter',
    inputField: '.js-input-field',
    inputFieldWrapper: '.js-field-wrapper, .form-group',
}

/**
 * @description Initializes input field characters counter functionality
 */
function initEvents() {
    let $charactersCounter = $(SELECTORS.charactersCounter);
    if (!$charactersCounter || !$charactersCounter.length) {
        return;
    }

    let $inputField = getInputFieldParent($(SELECTORS.charactersCounter)).find(SELECTORS.inputField);
    $inputField.on('input', handleCounter);
}

const getInputFieldParent = ($element) => $element.closest(SELECTORS.inputFieldWrapper);


/**
 * @description Count characters on input and update storefront value
 */
function handleCounter() {
    let $input = $(this);
    if (!$input) {
        return;
    }

    getInputFieldParent($input).find(SELECTORS.charactersCounter).text($(this).val().length);
}

exports.init = function () {
    initEvents();
}
