'use strict';

require('select2/dist/js/select2.full.min.js');
require('./select2-dropdownPosition.js');

const { CLASSES } = require('eminence/utils/globals');

const SELECTORS = {
    body: 'body',
    select: '.js-select',
    selectDropdownAbove: '.js-select-dropdown-above',
    selectDropdownBelow: '.js-select-dropdown-below',
    slickSlider: '.js-slick-slider',
};

function resultState(data, container) {
    if (data.element) {
        $(container).addClass($(data.element).attr('class'));
    }
    return data.text;
}

function customSelect() {
    $(SELECTORS.select).select2({
        minimumResultsForSearch: -1,
        width: '100%'
    });

    $(SELECTORS.selectDropdownAbove).each(function() {
        $(this).select2({
            dropdownPosition: 'above',
            minimumResultsForSearch: -1,
            width: '100%',
            dropdownParent: $(this).parent()
        });
    });

    $(SELECTORS.selectDropdownBelow).each(function() {
        $(this).select2({
            dropdownPosition: 'below',
            minimumResultsForSearch: -1,
            width: '100%',
            dropdownParent: $(this).parent(),
            templateResult: resultState
        });
    });
}

$(SELECTORS.selectDropdownBelow).each(function() {
    $(this).on('select2:open', handleSelectOpen);
});

$(SELECTORS.selectDropdownBelow).each(function() {
    $(this).on('select2:close', handleSelectClose);
});

function handleSelectOpen() {
    let $slider = $(this).parents(SELECTORS.slickSlider);
    if (!$slider || !$slider.length > 0) {
        return;
    }

    $slider.addClass(CLASSES.selectOpen);
}

function handleSelectClose() {
    let $slider = $(this).parents(SELECTORS.slickSlider);
    if (!$slider || !$slider.length > 0) {
        return;
    }

    $slider.removeClass(CLASSES.selectOpen);
}

const initEvents = () => {
    $(SELECTORS.body).on('select:reInit', () => customSelect());
};

module.exports = function () {
    initEvents();
    customSelect();
};

module.exports.customSelect = customSelect;
