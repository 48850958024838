'use strict';

var base = require('base/product/base');
var basketPopUp = require('eminence/components/basketPopup');

const SELECTORS = {
    body: 'body',
    quantityAndAddToCartContainer: '.js-quantity-add-to-cart-container',
};

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 * @param {object} form - object containing {pid, pidsObj, childProducts, quantity}
 */
function handlePostCartAdd(response, form) {
    $('.minicart').trigger('count:update', response);
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        // chooseBonusProducts(response.newBonusDiscountLineItem);
        base.methods.editBonusProducts(response.newBonusDiscountLineItem);
    } else {
        basketPopUp(response, form);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).data('pid'),
            ID: $(this).data('pid'),
            quantity: parseInt($(this).data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $('.set-items').length) {
        quantitySelected = $($el).closest(SELECTORS.quantityAndAddToCartContainer).find('.quantity-select');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-select');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-select');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
        quantitySelected = $('.quantity-select');
    }
    return quantitySelected;
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
var getPidValue = function ($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    }

    return pid;
}
base.getPidValue = getPidValue;

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

base.addToCart = function () {
    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;

        $('body').trigger('product:beforeAddToCart', this);

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            $('.product-detail.set-item').each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).find('.product-id').text(),
                        qty: $(this).find('.quantity-select').val() || 1,
                        options: getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }

        pid = getPidValue($(this));

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
        }

        addToCartUrl = getAddToCartUrl();

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: getChildProducts(),
            quantity: getQuantitySelected($(this))
        };

        if (!$('.bundle-item').length) {
            form.options = getOptions($productContainer);
        }

        $(this).trigger('updateAddToCartFormData', form);

        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    handlePostCartAdd(data, form);
                    $('body').trigger('product:afterAddToCart', data);
                    $.spinner().stop();
                    base.miniCartReportingUrl(data.reportingURL);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}

base.selectAttribute = function() {
    $(document).on('change', 'select[class*="select-attribute-"], .options-select', function (e) {
        e.preventDefault();

        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }
        base.attributeSelect(e.currentTarget.value, $productContainer);
    });
}

base.addWishlistToCart = function() {
    $(document).on('click', 'button.js-add-wishlist-to-cart', function (e) {
        e.preventDefault();
        var $this = $(this);
        var pid = $this.data('product-id');
        var qty = $this.data('qty');
        var addToCartUrl = getAddToCartUrl();

        var form = {
            pid: pid,
            pidsObj: '',
            childProducts: [],
            quantity: qty
        };
        if (addToCartUrl) {
            $.spinner().start();
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    $.spinner().stop();
                    if (!data.error) {
                        location.reload();
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}

base.addSampleGWPProduct = function () {
    $(document).on('click', '.js-bonus-products-button', function (e) {
        e.preventDefault();
        var $this = $(this);
        var $parentContainer = $this.closest('.js-bonus-products-container');
        var $productDetailContainer = $this.closest('.js-choice-of-bonus-product');
        var queryString = '?pids=';
        var url = $parentContainer.data('addtocarturl');
        var pidsObject = {
            bonusProducts: []
        };

        var qtyOption = parseInt($productDetailContainer.data('qty'), 10);

        var option = null;
        if (qtyOption > 0) {
            pidsObject.bonusProducts.push({
                pid: $productDetailContainer.data('pid'),
                qty: qtyOption,
                options: [option]
            });
            pidsObject.totalQty = 1;
        }
        queryString += JSON.stringify(pidsObject);
        queryString = queryString + '&uuid=' + $parentContainer.data('uuid');
        queryString = queryString + '&pliuuid=' + $parentContainer.data('pliuuid');
        $.spinner().start();
        $.ajax({
            url: url + queryString,
            method: 'POST',
            success: function (data) {
                if (data.error) {
                    if ($('.add-to-cart-messages').length === 0) {
                        $('body').append('<div class="add-to-cart-messages"></div>');
                    }
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-danger add-to-basket-alert text-center"'
                        + ' role="alert">'
                        + data.errorMessage + '</div>'
                    );
                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                    }, 3000);
                } else {
                    $this.addClass('h-hide');
                    $productDetailContainer.find('.js-bonus-products-checked').show();
                    $('.configure-bonus-product-attributes').html(data);
                    if ($('.add-to-cart-messages').length === 0) {
                        $('body').append('<div class="add-to-cart-messages"></div>');
                    }
                    $('.minicart-quantity').html(data.totalQty);
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-success add-to-basket-alert text-center"'
                        + ' role="alert">'
                        + data.msgSuccess + '</div>'
                    );
                    setTimeout(function () {
                        if ($('.cart-page').length) {
                            location.reload();
                        }
                    }, 1500);
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}

base.removeSampleGWPProduct = function () {
    $(document).on('click', '.js-change-bonus-product', function (e) {
        e.preventDefault();
        var $this = $(this);
        var url = $this.attr('href');
        $.spinner().start();

        $.ajax({
            url: url,
            method: 'GET',
            data: {
                pid: $this.data('pid'),
                uuid: $this.data('uuid')
            },
            success: function (data) {
                if (data.basket) {
                    location.reload();
                } else {
                    if ($('.add-to-cart-messages').length === 0) {
                        $('body').append('<div class="add-to-cart-messages"></div>');
                    }
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-danger add-to-basket-alert text-center"'
                        + ' role="alert">'
                        + data.errorMessage + '</div>'
                    );
                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                    }, 3000);
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}

module.exports = base;
